import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{staticClass:"mt-10",staticStyle:{"max-height":"75vh"},attrs:{"fluid":""}},[_c(VCard,{staticStyle:{"height":"100%"}},[_c(VTabs,{attrs:{"grow":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.items),function(item){return _c(VTab,{key:item.tab,on:{"click":function($event){return _vm.handleChange(_vm.tab)}}},[_vm._v(" "+_vm._s(item.tab)+" ")])}),1),_c(VTabsItems,{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.items),function(item){return _c(VTabItem,{key:item.tab},[_c(VCard,{attrs:{"text":""}},[_c(item.content,{tag:"component"})],1)],1)}),1),_c(VDivider),_c(VCardActions,{staticClass:"justify-center"},[(_vm.tab !== 0)?_c(VBtn,{staticClass:"app--buttons",attrs:{"outlined":"","rounded":"","fab":"","color":"deep-orange accent-4"},on:{"click":_vm.previous}},[_c(VIcon,{staticClass:"mx-1"},[_vm._v("arrow_back")])],1):_vm._e(),(_vm.tab !== _vm.items.length - 1)?_c(VBtn,{staticClass:"app--buttons",attrs:{"outlined":"","rounded":"","fab":"","color":"primary"},on:{"click":_vm.next}},[_c(VIcon,{staticClass:"mx-1"},[_vm._v("arrow_forward")])],1):_vm._e(),(_vm.tab === _vm.items.length - 1)?_c(VBtn,{staticClass:"app--buttons",attrs:{"tile":"","rounded":"","color":"primary","disabled":_vm.currentApplication.status === 'Released'},on:{"click":_vm.submit}},[_vm._v(" Submit For Approval "),_c(VIcon,{staticClass:"mx-2"},[_vm._v("assignment_turned_in")])],1):_vm._e()],1)],1),_c('Acceptance',{attrs:{"acceptanceDialog":_vm.acceptanceDialog,"periods":_vm.periods},on:{"close-dialog":_vm.closeDialog}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }