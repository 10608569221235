<template>
  <v-container fluid class="mt-10" style="max-height:75vh">
    <v-card style="height:100%">
      <v-tabs v-model="tab" grow>
        <v-tab v-for="item in items" :key="item.tab" @click="handleChange(tab)">
          {{ item.tab }}
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item v-for="item in items" :key="item.tab">
          <v-card text>
            <component :is="item.content" />
          </v-card>
        </v-tab-item>
      </v-tabs-items>
      <v-divider></v-divider>
      <v-card-actions class="justify-center" style="">
        <v-btn
          v-if="tab !== 0"
          outlined
          rounded
          fab
          color="deep-orange accent-4"
          class="app--buttons"
          @click="previous"
        >
          <v-icon class="mx-1">arrow_back</v-icon>
          <!-- Previous -->
        </v-btn>
        <!-- <v-spacer /> -->
        <v-btn
          v-if="tab !== items.length - 1"
          outlined
          rounded
          fab
          color="primary"
          class="app--buttons"
          @click="next"
        >
          <!-- Next -->
          <v-icon class="mx-1">arrow_forward</v-icon>
        </v-btn>
        <v-btn
          tile
          rounded
          color="primary"
          class="app--buttons"
          v-if="tab === items.length - 1"
          @click="submit"
          :disabled="currentApplication.status === 'Released'"
        >
          Submit For Approval
          <v-icon class="mx-2">assignment_turned_in</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
    <!--Acceptance Dialog-->
    <Acceptance
      :acceptanceDialog="acceptanceDialog"
      :periods="periods"
      @close-dialog="closeDialog"
    />
  </v-container>
</template>

<script>
import Introduction from "./Introduction.vue";
import Guidelines from "./Guidelines.vue";
import Summary from "./Summary.vue";
import Acceptance from "./Acceptance.vue";

export default {
  name: "AcceptanceForm",
  components: { Introduction, Guidelines, Summary, Acceptance },
  data() {
    return {
      tab: null,
      items: [
        { tab: "INTRODUCTION", content: "Introduction" },
        { tab: "GUIDELINES", content: "Guidelines" },
        { tab: "SUMMMARY", content: "Summary" },
        // { tab: "ACCEPTANCE", content: "Acceptance" },
      ],
      acceptanceDialog: false,
    };
  },
  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.$store.dispatch("appraisal/getHrEmployeeWithJDS", {
        no: v.userData.employee,
      });

      v.$store.dispatch("Setup/getProfilePic", {
        employee_code: v.userData.employee,
      });
      v.$store.dispatch("appraisal/getApplications");
      //console.log(atob(v.$route.params.applicationNo));
      v.$store.dispatch(
        "appraisal/getApplication",
        atob(v.$route.params.applicationNo)
      );
      v.$store.dispatch("appraisal/getPeriods");
    });
  },
  created() {
    this.tab = sessionStorage.getItem("key");
  },
  computed: {
    userData: function () {
      return this.$store.getters["auth/user"];
    },

    periods() {
      return this.$store.getters["appraisal/appraisalGetter"]("periods");
    },
    currentReview() {
      return this.$store.getters["appraisal/appraisalGetter"]("templates");
    },
    currentApplication() {
      return this.$store.getters["appraisal/appraisalGetter"]("application");
    },
  },
  methods: {
    previous() {
      this.tab -= 1;
      this.items[this.tab].disabled = false;
      this.items[this.tab + 1].disabled = true;
    },
    next() {
      if (this.tab < this.items.length) {
        this.proceedNext();
      } else {
        this.proceedNext();
      }
    },
    proceedNext() {
      this.tab += 1;
      this.items[this.tab].disabled = false;
      this.items[this.tab - 1].disabled = true;
    },
    submit() {
      //this.$store.dispatch("");
      // emit this event on declaration.
      //Event.$emit("submitDeclaration");
      this.acceptanceDialog = true;
    },
    closeDialog() {
      this.acceptanceDialog = false;
    },

    handleChange(index) {
      sessionStorage.setItem("key", index);
    },
  },
};
</script>
